<template>
  <div :class="$style.root">
    <h1 v-if="$route.meta.title" class="mb-4">{{$route.meta.title}}</h1>
    <div class="mb-4 clearfix">
      <VBtn depressed @click="$router.push({name: 'elkp/EmployeeDetailView', params: { employee: employeeId, contract: 'employee' }})">
        <VIcon>
          mdi-arrow-left
        </VIcon>
        К сотруднику
      </VBtn>
    </div>
    <VCard elevation="0" class="mb-6">
      <VCardTitle v-if="employeeId">
        Привязка договора к сотруднику "<router-link :to="{name: 'elkp/EmployeeDetailView', params: { employee: employeeId, contract: 'employee' }}">{{ employeeId }}</router-link>"
      </VCardTitle>
      <VCardText>
        <ContractList
            :items="contracts.items"
            :count="contracts.count"
            :page="page"
            :size="size"
            :loading="loading"
            :disabled="!hasRole(['CONTRAGENT', 'CONTRACT_HOLDER'], 'elkp')"
            :selected.sync="contractsSelected"
            @click:row="({ id }) => $router.push({ name: 'elkp/EmployeeDetailView', params: { employee: id }})"
        />
      </VCardText>
      <VFooter v-if="hasRole(['CONTRAGENT', 'CONTRACT_HOLDER'], 'elkp')" app>
        <div class="py-3 grow">
          <VBtn color="primary" depressed :loading="loading" :disabled="!contractsSelected.length" @click="onBind">
            Привязать выбранные договоры
          </VBtn>
          <VBtn v-if="contractsSelected.length" color="secondary" depressed class="ml-4" @click="contractsSelected = []">
            Отмена
          </VBtn>
        </div>
      </VFooter>
    </VCard>
  </div>
</template>

<script>
import {debounce, get, map} from 'lodash-es';
import { mapGetters, mapActions } from 'vuex';
import ContractList from '@/components/elkp/ContractList/ContractList';
export default {
  name: 'ContractLinkView',
  components: {
    ContractList,
  },
  props: {
    page: { type: Number, default: 1 },
    size: { type: Number, default: 20 },
    filter: { type: Object }
  },
  data: function() {
    return {
      dialog: false,
      contractsSelected: [],
    }
  },
  computed: {
    ...mapGetters({
      loading: 'elkp/pending',
      contracts: 'elkp/contracts',
      hasRole: 'user/hasRole',
    }),
    employeeId: function() {
      return get(this.filter, '!employeeId');
    },
  },
  methods: {
    ...mapActions({
      fetchContractList: 'elkp/fetchContractList',
      addEmployeeToContract: 'elkp/addEmployeeToContract',
    }),
    onUpdateState: debounce(function() {
      const { page, size, filter } = this;
      this.fetchContractList({ page, size, filter });
    }, 500),
    onBind: function() {
      const contract = map(this.contractsSelected, 'id');
      const employees = [this.employeeId];
      this.addEmployeeToContract({ employees, contract }).then((result) => {
        if (result) this.onUpdateState();
      });
    },
  },
  watch: {
    page: function() {
      this.onUpdateState();
    },
    size: function() {
      this.onUpdateState();
    },
    filter: {
      deep: true,
      handler: function() {
        this.onUpdateState();
      }
    }
  },
  mounted() {
    this.onUpdateState();
  }
}
</script>

<style module lang="scss">
.root {}
</style>
